import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import useElementRenderer from '../hooks/element-renderer'

const TermsOfUsePage = () => {
  const { formatMessage } = useIntl()
  const { renderParagraphs, renderFlatListItems } = useElementRenderer(
    'termsOfUse'
  )

  return (
    <Layout headerLinkColor="lg:text-purple">
      <SEO
        lang={formatMessage({ id: 'lang' })}
        title={formatMessage({ id: 'pages.termsOfUse.title' })}
        description={formatMessage({ id: 'pages.termsOfUse.description' })}
      />

      <div className="pt-48 pb-24 bg-white text-gray">
        <div className="custom-container custom-container--large text-justify">
          <h1 className="heading text-center">
            {formatMessage({ id: 'pages.termsOfUse.title' })}
          </h1>

          {/* Introduction */}
          <section className="mt-12">
            {renderParagraphs('introduction', 3)}
          </section>

          {/* Definitions */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({
                id: 'pages.termsOfUse.sections.definitions.title',
              })}
            </h2>

            {renderParagraphs('definitions', 1)}

            <ul className="mt-4 pl-4 list-disc">
              {renderFlatListItems('definitions', 1, 6)}
            </ul>
          </section>

          {/* Concept */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({ id: 'pages.termsOfUse.sections.concept.title' })}
            </h2>

            {renderParagraphs('concept', 2)}

            <ul className="mt-4 pl-4 list-disc">
              {renderFlatListItems('concept', 1, 3)}
            </ul>

            {renderParagraphs('concept', 3, 3)}
          </section>

          {/* Registration */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({
                id: 'pages.termsOfUse.sections.registration.title',
              })}
            </h2>

            {renderParagraphs('registration')}

            <ul className="mt-4 pl-4 list-disc">
              {renderFlatListItems('registration', 1, 6)}
            </ul>

            {renderParagraphs('registration', 1, 2)}

            <ol className="mt-4 pl-4 list-decimal">
              {renderFlatListItems('registration', 2, 8)}
            </ol>

            {renderParagraphs('registration', 1, 3)}

            <ol className="mt-4 pl-4 list-decimal">
              {renderFlatListItems('registration', 3, 3)}
            </ol>

            {renderParagraphs('registration', 1, 4)}

            <ol className="mt-4 pl-4 list-decimal">
              {renderFlatListItems('registration', 4, 3)}
            </ol>
          </section>

          {/* Electronic devices */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({
                id: 'pages.termsOfUse.sections.electronicDevices.title',
              })}
            </h2>

            {renderParagraphs('electronicDevices', 3)}
          </section>

          {/* Intelectual property */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({
                id: 'pages.termsOfUse.sections.intelectualProperty.title',
              })}
            </h2>

            {renderParagraphs('intelectualProperty', 5)}
          </section>

          {/* License */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({ id: 'pages.termsOfUse.sections.license.title' })}
            </h2>

            {renderParagraphs('license', 5)}
          </section>

          {/* Authorization */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({
                id: 'pages.termsOfUse.sections.authorization.title',
              })}
            </h2>

            {renderParagraphs('authorization', 1)}

            <ol className="mt-4 pl-4 list-decimal">
              {renderFlatListItems('authorization', 1, 3)}
            </ol>

            {renderParagraphs('authorization', 1, 2)}
          </section>

          {/* Account */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({ id: 'pages.termsOfUse.sections.account.title' })}
            </h2>

            {renderParagraphs('account', 5)}
          </section>

          {/* Prohibitions */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({
                id: 'pages.termsOfUse.sections.prohibitions.title',
              })}
            </h2>

            {renderParagraphs('prohibitions', 8)}
          </section>

          {/* Exemption */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({
                id: 'pages.termsOfUse.sections.exemption.title',
              })}
            </h2>

            {renderParagraphs('exemption', 5)}

            <ol className="mt-4 pl-4 list-decimal">
              {renderFlatListItems('exemption', 1, 4)}
            </ol>
          </section>

          {/* Warranties */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({
                id: 'pages.termsOfUse.sections.warranties.title',
              })}
            </h2>

            {renderParagraphs('warranties', 2)}
          </section>

          {/* Responsibility */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({
                id: 'pages.termsOfUse.sections.responsibility.title',
              })}
            </h2>

            {renderParagraphs('responsibility')}

            <ol className="mt-4 pl-4 list-decimal">
              {renderFlatListItems('responsibility', 1, 6)}
            </ol>
          </section>

          {/* Compensation */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({
                id: 'pages.termsOfUse.sections.compensation.title',
              })}
            </h2>

            {renderParagraphs('compensation')}
          </section>

          {/* Breach */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({ id: 'pages.termsOfUse.sections.breach.title' })}
            </h2>

            {renderParagraphs('breach', 6)}
          </section>

          {/* Breach */}
          <section className="mt-12">
            <h2 className="heading text-center text-2xl">
              {formatMessage({
                id: 'pages.termsOfUse.sections.legislation.title',
              })}
            </h2>

            {renderParagraphs('legislation')}
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default TermsOfUsePage
