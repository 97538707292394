import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

function ElementRenderer(page) {
  const { formatMessage } = useIntl()

  const renderParagraphs = (section, count = 1, start = 1) => {
    const elements = []
    const end = start + (count - 1)
    for (let i = start; i <= end; i++) {
      elements.push(
        <p
          key={i}
          className="mt-4"
          dangerouslySetInnerHTML={{
            __html: formatMessage({
              id: `pages.${page}.sections.${section}.p${i}`,
            }),
          }}
        />
      )
    }
    return elements
  }

  const renderFlatListItems = (section, listNumber, count) => {
    const listItems = []
    for (let i = 1; i <= count; i++) {
      listItems.push(
        <li
          key={i}
          className="pl-2"
          dangerouslySetInnerHTML={{
            __html: formatMessage({
              id: `pages.${page}.sections.${section}.l${listNumber}.li${i}`,
            }),
          }}
        />
      )
    }
    return listItems
  }

  return {
    renderParagraphs,
    renderFlatListItems,
  }
}

export default ElementRenderer
